
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import ItemService from '@/services/item-service';
import { State, Action, Getter } from 'vuex-class';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import {
  BuildDieLineStyleSheet,
  BuildSVGMask,
  BuildDieLineKeyFrames,
  ImgMaskBuilder
} from '@/Builders/builders';
import { KeyFrameDynamicValues } from '@/store/models/KeyFrameModel';
import { SetIdsForSVGElements } from '@/helpers/ObjectHelper';
import { downloadFile, downloadImage } from '@/helpers/ApiHelper';
@Component
export default class extends Vue {
  /* Properties */
  @Prop({ required: true })
  itemId!: string;
  @Prop({ required: true })
  version!: number;
  @Prop()
  shouldShowDieLines!: boolean;
  @Prop()
  shouldDisableAnimation!: boolean;
  @Prop()
  maskBuildType!: string;
  @Prop()
  maxHeight!: number;
  @Prop({ default: false })
  hover!: boolean;
  @Prop()
  id!: string;
  @Prop()
  orderlineId!: string;
  @Prop()
  src!: string;
  /* Store Actions */
  /* Watchers */
  @Watch('isGrabbingHiRezImage')
  onHighRezImageChange() {
    this.$emit('grabbingHighRezImg', this.isGrabbingHiRezImage);
  }
  @Watch('maskBuildType')
  onchange(val: any) {
    ImgMaskBuilder(val, this.trimlineElement, this.safelineElement, this.isSpecial);
  }
  @Watch('animationLocation')
  onAnimationLocationChange() {
    this.animateTo();
  }
  /* Data */
  highRezSrc: string = '';
  dieLines: any = null;
  trimlineElement: any = null;
  safelineElement: any = null;
  svgElement: any = null;
  keyFrames: string = '';
  newStyleSheet: HTMLStyleElement = document.createElement('style');
  isGrabbingHiRezImage: boolean = false;
  isAnimating: boolean = false;
  hideImage: boolean = true;
  animationTimeout: any;
  isSpecial: boolean = false;
  animationLocation: string = '0';
  testString: string = '';
  originalHeight: number = 0;
  originalWidth: number = 0;

  menuPositionX: number = 0;
  menuPositionY: number = 0;
  showMenu: boolean = false;
  menuActions: string[] = ['Download'];
  /* Utility Functions */
  animateTo() {
    switch (parseInt(this.animationLocation)) {
      case 1:
        this.moveTo('moveTopLeft');
        return;
      case 2:
        this.moveTo('moveUp');
        return;
      case 3:
        this.moveTo('moveTopRight');
        return;
      case 4:
        this.moveTo('moveRight');
        return;
      case 5:
        this.moveTo('moveBottomRight');
        return;
      case 6:
        this.moveTo('moveDown');
        return;
      case 7:
        this.moveTo('moveBottomLeft');
        return;
      case 8:
        this.moveTo('moveLeft');
        return;
    }
  }
  async GetDieLines() {
    try {
      this.keyFrames = BuildDieLineKeyFrames();
      const response = await ItemService.GetDieLines(this.itemId, this.orderlineId);
      this.dieLines = response.data;
      setTimeout(() => {
        let svgList = Array.from(
          document.querySelectorAll<SVGSVGElement>('svg:not(#j1l-logo-svg):not(.lottie)')
        );
        this.isSpecial = this.dieLines.Shape === 'Special';

        SetIdsForSVGElements(svgList, this.isSpecial);
        console.log(svgList);
        this.trimlineElement = document.getElementById('trimLineId');
        this.safelineElement = document.getElementById('safetyLineId');
        if (this.trimlineElement) {
          let g = this.trimlineElement.parentNode;
          this.svgElement = g!.parentElement;
        }
        BuildSVGMask(this.id || 'imgId', this.trimlineElement, this.isSpecial, false);
        this.trimlineElement = document.getElementById('trimLineId');
        const img = document.getElementById('imgId');
        this.originalHeight = img!.clientHeight;
        this.originalWidth = img!.clientWidth;
        if (this.trimlineElement) {
          this.buildKeyFrameStyleSheet();
        }
        this.hideImage = false;
        ImgMaskBuilder(
          this.maskBuildType,
          this.trimlineElement,
          this.safelineElement,
          this.isSpecial
        );
      }, 0);
    } catch (err) {
      //
    }
  }

  buildKeyFrameStyleSheet() {
    this.newStyleSheet.type = 'text/css';
    let safetyLineElement: any = document.getElementById('safetyLineId');
    let distanceX: number = this.dieLines.distanceX;
    let distanceY: number = this.dieLines.distanceY;
    let dynamicValues = BuildDieLineStyleSheet(
      new KeyFrameDynamicValues({ distanceX, distanceY }),
      this.keyFrames
    );
    this.newStyleSheet.innerHTML = dynamicValues;
    document.getElementsByTagName('head')[0].appendChild(this.newStyleSheet);
  }
  moveTo(className: string) {
    this.animatedEndHandler();
    this.trimlineElement!.setAttribute('class', className);
    this.isAnimating = false;
  }
  playAnimation(className: string = 'fullAnimation') {
    this.animatedEndHandler();
    if (this.trimlineElement.classList.contains(className)) {
      this.pause();
    } else {
      let timeout = 0;
      if (className === 'fullAnimate') {
        this.trimlineElement!.setAttribute('class', 'moveTopRight');
        timeout = 1000;
      }
      setTimeout(() => {
        this.trimlineElement!.setAttribute('class', className);
      }, timeout);
      this.isAnimating = true;
    }
  }
  pause() {
    if (this.trimlineElement.classList.contains('pause')) {
      this.trimlineElement.classList.remove('pause');
      this.trimlineElement.classList.add('play');
      this.isAnimating = true;
    } else {
      this.trimlineElement.classList.remove('play');
      this.trimlineElement.classList.add('pause');
      this.isAnimating = false;
    }
  }
  animatedEndHandler(className: string = 'fullAnimation') {
    this.trimlineElement!.classList.remove(className);
    this.trimlineElement!.removeEventListener(
      'webkitAnimationEnd',
      this.animatedEndHandler
    );
  }

  handleMenuOptionClick(action: string) {
    switch (action) {
      case 'Download':
        this.downloadFile();
        return;
    }
  }

  rightClickImage(event: PointerEvent) {
    event.preventDefault();
    this.showMenu = false;
    this.menuPositionX = event.clientX;
    this.menuPositionY = event.clientY;
    this.$nextTick(() => {
      this.showMenu = true;
    });
  }

  downloadFile() {
    try {
      var img = document.getElementById('imgId') as HTMLImageElement;
      ItemService.DownloadImage(img.src.split('/j1lImages/')[1]);
    } catch (error) {
      SetSnackBar('Could not download file');
    }
  }

  /* Loaders */
  async GetHiRezImg() {
    const hirezstream = await ItemService.GetItemHighRezGraphicAsStream(
      this.itemId,
      this.version
    );
    this.highRezSrc = hirezstream.data;
    if (this.shouldShowDieLines) this.GetDieLines();
  }
  get maxHeightImage() {
    return this.maxHeight ? this.maxHeight + 'px' : '80vh';
  }
  /* Mounted */
  mounted() {
    if (this.src) {
      this.highRezSrc = this.src;
    } else {
      this.GetHiRezImg();
    }
  }
  created() {}
  /* Created */
}
