
import { ConfirmationDialogInfo } from '@/store/models/CustomerModel';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component
export default class extends Vue {
  /* Properties */
  @Prop()
  confirmationText!: string;
  @Prop()
  namesToRollback!: string[];
  @Prop({ default: 'Are you sure?' })
  confirmationTitle!: string;
  @Prop()
  isLoading!: boolean;
  @Prop()
  actionText!: string;
  @Prop({ default: 'Cancel' })
  cancelText!: string;
  /* Store Actions */
  /* Watchers */
  /* Data */
  dialog: boolean = false;
  target!: any;
  confirmationInfo: ConfirmationDialogInfo = new ConfirmationDialogInfo();
  /* Computed */
  get actionButtonText() {
    return this.actionText || 'I am sure';
  }
  /* Utility Functions */
  handleResponse(didAccept: boolean) {
    if (this.target) {
      this.$emit('response', { didAccept: didAccept, target: this.target });
    } else {
      this.$emit('response', didAccept);
    }
    this.closeDialog();
  }
  openDialog(confirmationInfo?: ConfirmationDialogInfo) {
    if (confirmationInfo) this.confirmationInfo = confirmationInfo;
    this.dialog = true;
  }
  closeDialog() {
    this.dialog = false;
  }
  setTarget(data: any) {
    this.target = data;
  }
  /* Loaders */
  /* Mounted */
  /* Created */
}
