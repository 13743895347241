
import AuthService from '@/services/auth-service';
import { OrdersService } from '@/services/orders-service';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import LeftDrawer from '@/components/LeftDrawer.vue';
import RightDrawer from '@/components/RightDrawer.vue';
import { CustomerOrder } from '@/store/models/OrderlineModel';
import AdminService from '@/services/admin-service';
import LottieIcon from './LottieIcon.vue';

@Component({
  components: { LeftDrawer, RightDrawer, LottieIcon }
})
export default class extends Vue {
  $refs!: {
    helpDeskDialog: HTMLFormElement;
  };
  @Getter('displayName', { namespace: 'profile' })
  displayName!: string;
  @Getter('getUserValidation', { namespace: 'profile' })
  userValidation!: any;
  @Getter('getShoppingCart', { namespace: 'orderlines' })
  shoppingCart!: CustomerOrder;
  @Action('logout', { namespace: 'profile' }) logout: any;
  @Prop() color!: string;
  @Prop() desktopColor!: string;
  @Prop() shouldUseNonLoggedIn!: boolean;
  isDesktop: boolean = false;
  isAdmin: boolean = false;
  shouldUseMobile: boolean = false;
  drawer: boolean = false;
  rightDrawer: boolean = false;
  snackbar: boolean = false;
  actionText: string = 'Close';
  snackbarText: string = '';
  emitText: string = '';
  snackbarTimeout: number = -1;
  isAppBarFlat: boolean = true;
  accountOptions: any = [
    {
      text: 'Company Profile',
      icon: 'mdi-domain',
      id: 1
    },
    {
      text: 'Address Book',
      icon: 'mdi-calendar-text',
      id: 2
    },
    {
      text: 'Invoices',
      icon: 'mdi-receipt',
      id: 5
    },
    {
      text: 'Account Settings',
      icon: 'mdi-cog-outline',
      id: 3
    },
    {
      text: 'Log out',
      icon: 'mdi-lock-open-outline',
      id: 4
    }
  ];
  $vuetify: any;

  async goToVersion1() {
    const { data } = await AuthService.GetCurrentUser();
    AdminService.addActivityStream(`${data} moved to Version 1`);
    window.open(`https://www.just1label.com/Account/Login?userId=${data}`);
  }

  handleOptionSelection(id: number) {
    switch (id) {
      case 1: {
        this.$router.push('/companyprofile');
        return;
      }
      case 2: {
        this.$router.push('/addressbook');
        return;
      }
      case 3: {
        this.$router.push('/accountsettings');
        return;
      }
      case 4: {
        this.logout();
        return;
      }
      case 5: {
        this.$router.push('/invoicemanagement');
      }
    }
  }

  async created() {
    this.isDesktop = !this.isMobile() && !this.isTablet();
    this.$watch(
      () => {
        return this.$vuetify.breakpoint.width;
      },
      val => {
        this.isDesktop = !this.isMobile() && !this.isTablet();
      }
    );
    const response = await AuthService.isAdmin();
    this.isAdmin = response.data;
  }
  isMobile() {
    this.shouldUseMobile = this.$vuetify.breakpoint.width <= 768;
    return this.shouldUseMobile;
  }
  isTablet() {
    return (
      this.$vuetify.breakpoint.width >= 768 && this.$vuetify.breakpoint.width <= 1200
    );
  }
  goTo(path: string): void {
    this.$router.push(path);
  }
  update(value: boolean) {
    this.drawer = value;
  }
  emitSpecialResponse() {
    this.snackbar = false;
    this.$emit(this.emitText);
  }
  closeRightDrawer() {
    this.rightDrawer = false;
  }
  getDisplayName() {
    return this.displayName;
  }
  isUserAdmin() {
    return this.isAdmin;
  }
  setAppBarElevation(isFlat: boolean) {
    this.isAppBarFlat = isFlat;
  }
  mounted() {}
}
