
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { QuickDialog } from '@/store/models/DialogModel';
import { QuoteService } from '@/services/quote-service';
import { ItemService } from '@/services/item-service';
import J1lImg from '@/components/J1lImg.vue';
import ItemApprovalIcon from '@/components/Icons/ItemApprovalIcon.vue';

@Component({
  components: { J1lImg, ItemApprovalIcon }
})
export default class extends Vue {
  $refs!: {};
  /* Properties */
  @Prop({ required: true }) data!: QuickDialog;
  @Prop() shouldDisplayCardActions!: boolean;
  @Prop() isImageDoneProccessing!: boolean;

  /* Data */

  /* Methods */

  /* Computed */

  /* Mounted */
  async mounted() {}
  /* Created */
  created() {}
  /* Created */
}
