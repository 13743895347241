
import { ConstValues } from '@/ConstValues';
import { CustomerService } from '@/services/customer-service';
import { Customer } from '@/store/models/CustomerModel';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import BillingAddressFormatter from '@/components/formatterComponents/BillingAddressFormatter.vue';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { Getter } from 'vuex-class';
import AuthService from '@/services/auth-service';
@Component({
  components: {
    BillingAddressFormatter
  }
})
export default class ConfirmCustomerDetailsDialog extends Vue {
  /* Properties */
  /* Store Actions */
  @Getter('isAuthenticated', { namespace: 'profile' }) isAuthenticated!: boolean;
  @Getter('isAdmin', { namespace: 'profile' }) isAdmin!: boolean[];
  /* Watchers */
  /* Data */
  isLoading: boolean = false;
  dialog: boolean = false;
  isFormValid: boolean = false;
  customer: Customer = new Customer();
  states: { id: string; name: string }[] = [];
  countries: any[] = [];
  isEditingBillingAddress: boolean = false;
  /* Computed */
  get ConstValues() {
    return ConstValues;
  }
  /* Async Functions */
  async saveBasicInfo() {
    this.isLoading = true;
    try {
      await CustomerService.UpdateCustomerBasicInfo(
        this.customer.ConvertToBasicInfoModel()
      );
      this.closeDialog();
      SetSnackBar('Updated Information Successfully');
    } catch (err) {
      console.log(err);
      SetSnackBar(
        'Error has occured, please contact techsupport@just1label.com if you are experiencing any issues'
      );
    }
    this.isLoading = false;
  }
  /* Utility Functions */
  openDialog() {
    this.GetStates();
    this.GetCountries();
    this.dialog = true;
  }
  closeDialog() {
    this.dialog = false;
  }
  loadData() {
    setTimeout(async () => {
      if (!this.isAuthenticated) return;
      let { data: isConfirmed } = await AuthService.isConfirmed();
      if (!isConfirmed) return;
      await this.getCustomer();
      if (
        this.customer.Name &&
        !this.customer.Options.InfoVerifiedDate &&
        !this.isAdmin
        // this.customer.Name
      ) {
        this.openDialog();
      }
    }, 2000);
  }
  /* Loaders */
  async GetStates() {
    const response = await CustomerService.GetStates();
    this.states = Object.keys(response.data).map(key => ({
      id: key,
      name: response.data[key]
    }));
  }
  async GetCountries() {
    const response = await CustomerService.GetCountries();
    this.countries = response.data;
  }
  async getCustomer() {
    let { data } = await CustomerService.GetCurrentCustomer();
    this.customer = new Customer(data);
  }
  /* Mounted */
  async mounted() {
    this.loadData();
  }
  /* Created */
  /* Emmited Functions */
}
