
import AdminService from '@/services/admin-service';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component
export default class FooterBanner extends Vue {
  /* Properties */
  /* Store Actions */
  @Getter('isAuthenticated', { namespace: 'profile' }) isAuthenticated!: boolean;
  /* Watchers */
  @Watch('isAuthenticated')
  onAuthChange(val: boolean) {
    if (val) {
      // Check if the banner should be hidden based on the cookie value
      this.hideBanner = this.getCookie('hideBanner') === 'true';
    }
  }
  /* Data */
  hideBanner: boolean = true;
  shouldNotShowAgain: boolean = false;
  bannerText: string =
    'By using the Just One Label, LLC website, you agree to the use of cookies as described in our Cookies Policy. For the full Terms of Use, please click here.';
  /* Computed */
  /* Async Functions */
  /* Utility Functions */
  dismissBanner(): void {
    this.hideBanner = true;
    if (this.shouldNotShowAgain) {
      this.setCookie('hideBanner', 'true', 30); // Cookie will expire in 30 days
    }
    AdminService.addActivityStream(
      `Accepted use of cookies and terms of service. ${
        this.shouldNotShowAgain ? 'Will not show again for 30 days.' : ''
      }`
    );
  }

  setCookie(name: string, value: string, days: number): void {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${encodeURIComponent(
      value
    )};expires=${expires.toUTCString()};path=/`;
  }

  getCookie(name: string): string | undefined {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2)
      return parts
        .pop()
        ?.split(';')
        .shift();
    return undefined;
  }
  /* Loaders */
  /* Mounted */
  mounted(): void {
    // Check if the banner should be hidden based on the cookie value
    this.hideBanner =
      !this.isAuthenticated ||
      (this.isAuthenticated && this.getCookie('hideBanner') === 'true');
  }
  /* Created */
  /* Emmited Functions */
}
