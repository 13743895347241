
import { ConversionHelper } from '@/helpers/ConversionHelper';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { CustomerService } from '@/services/customer-service';
import { DepartmentTypes, HelpDeskRequestModel } from '@/store/models/CustomerModel';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import DialogLayout from './DialogLayout.vue';

@Component({
  components: { DialogLayout }
})
export default class extends Vue {
  $refs!: {
    defaultActions: HTMLFormElement;
  };
  /* Properties */
  @Prop() helpContext!: any;
  /* Store Actions */
  /* Watchers */
  @Watch('dialog')
  onDialogChange(val: any) {
    if (!val) {
      this.setToDefaultValue();
      this.closeDialog();
    }
  }
  /* Data */
  dialog: boolean = false;
  departmentOptions: any[] = [
    {
      id: 0,
      text: 'Artwork',
      departmentType: DepartmentTypes.Artwork
    },
    {
      id: 1,
      text: 'Using the website',
      departmentType: DepartmentTypes.Website
    },
    {
      id: 2,
      text: 'Ordering',
      departmentType: DepartmentTypes.Ordering
    },
    {
      id: 3,
      text: 'Billing',
      departmentType: DepartmentTypes.Billing
    },
    {
      id: 4,
      text: 'General',
      departmentType: DepartmentTypes.General
    }
  ];
  selectedDepartmentText: string = '';
  selectedDepartmentValue: DepartmentTypes | null = null;
  helpDeskStep: number = 0;
  shouldShowDuplicateAlert: boolean = false;
  isLoading: boolean = false;
  isCheckingForDuplicates = false;
  subject: string = '';
  questionBeingAsked: string = '';
  isPartNumberValid = false;
  newPartNumber: string = '';
  /* Utility Functions */
  async openDialog() {
    this.dialog = true;
  }
  closeDialog() {
    this.dialog = false;
  }
  setToDefaultValue() {
    this.subject = '';
    this.questionBeingAsked = '';
    this.selectedDepartmentText = '';
    this.selectedDepartmentValue = null;
    this.helpDeskStep = 0;
  }
  onDepartmentOptionClick(item: any) {
    this.selectedDepartmentText = item.text;
    this.selectedDepartmentValue = item.departmentType;
    this.helpDeskStep++;
  }
  onBackOrCancelClick(helpDeskStep: any) {
    switch (helpDeskStep) {
      case 0:
        this.closeDialog();
        break;
      case 1:
        this.setToDefaultValue();
        break;
      default:
        break;
    }
  }
  async sendEmail() {
    try {
      let model: HelpDeskRequestModel = new HelpDeskRequestModel({
        Subject: this.selectedDepartmentText + ': ' + this.subject,
        Question: this.questionBeingAsked,
        Department: this.selectedDepartmentValue,
        Url: window.location.href,
        BrowserType: window.navigator.userAgent
      });
      const response = await CustomerService.SendACustomerQuestionEmailToRequestedDepartment(
        model
      );

      SetSnackBar(
        "Thanks for reaching out! You're question has been sent and one of our Just 1 Label team will respond to you shortly."
      );

      this.closeDialog();
    } catch (err) {
      SetSnackBar('Something went wrong. Please refresh the page and try again.');
      console.log(err);
    }
  }
  get isDisabled() {
    return !this.subject || !this.questionBeingAsked;
  }

  /* Loaders */
  /* Mounted */
  /* Created */
}
