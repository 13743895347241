
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { CustomerService } from '@/services/customer-service';
import { FeedbackMsgModel } from '@/store/models/CustomerModel';
import html2canvas from 'html2canvas';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
@Component
export default class extends Vue {
  $refs!: {
    defaultActions: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  /* Watchers */
  @Watch('shouldShowDialog')
  onDialogChange(val: boolean) {
    if (!val) {
      this.model = new FeedbackMsgModel();
    }
  }
  /* Data */
  shouldShowDialog: boolean = false;
  isSubmittingReport: boolean = false;
  model: FeedbackMsgModel = new FeedbackMsgModel();
  /* Utility Functions */
  async openDialog() {
    this.model = await this.takeScreenshot();
    this.shouldShowDialog = true;
  }
  async takeScreenshot() {
    const canvas = await html2canvas(document.body);
    let img = canvas.toDataURL();
    const data: FeedbackMsgModel = {
      BrowserType: window.navigator.userAgent,
      Url: window.location.href,
      ScreenShot: img,
      ReportType: 'error',
      Message: this.model.Message,
      SubmissionTime: ''
    };
    return data;
  }
  async submitReport() {
    this.isSubmittingReport = true;
    try {
      await CustomerService.SendBugReport(this.model);
      SetSnackBar('Bug report submitted! We will take a look at this ASAP');
    } catch (err) {
      SetSnackBar(
        'We had an issue submitting your report. Please contact techsupport@just1label.com'
      );
    }
    this.isSubmittingReport = false;
    this.shouldShowDialog = false;
  }
  /* Loaders */
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
